import settingsActionCreatorFactory from 'typescript-fsa'
import { Theme } from '@mui/material'
import { Message } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import { AppLayout, EditLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
export const settingsActionCreator = settingsActionCreatorFactory('SETTINGS')

/**
 * Actions
 */

// settings actions
export const setPrefersDarkMode = settingsActionCreator<boolean>('SET_DARK_MODE')
export const setEditLayout = settingsActionCreator<EditLayout>('SET_EDIT_LAYOUT')
export const setAppLayout = settingsActionCreator<AppLayout>('SET_APP_LAYOUT')
export const setTheme = settingsActionCreator<Theme>('SET_THEME')
export const setCrudeAppName = settingsActionCreator<string>('SET_CRUDE_APP_NAME')
export const setMessage = settingsActionCreator<Message>('SET_MESSAGE')
export const setUser = settingsActionCreator<any>('SET_USER')
