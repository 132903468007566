import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  useTheme, Theme,
} from '@mui/material'
import { EntityData } from '@3m5/crude-frontend/dist/shared/types/dataTypes'
import Env from '../../shared/config/envConfig'
import { useTranslation } from 'react-i18next'
import DialogComponent from '@3m5/crude-frontend/dist/shared/components/MessageComponents/DialogComponent'
import { Message, Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import * as settingsActions from '../../store/settings/settings.actions'
import { useDispatch } from 'react-redux'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useHistory } from 'react-router-dom'

interface InjectedTableActionComponentProps {
  entity: string
  entityId: string
  treeViewLevel: number
  entityData: EntityData
  sharedData: Record<string, any>
}
const TableActionButtons: React.FC<InjectedTableActionComponentProps> = (props) => {
  const basePath = Env.basePath
  const dispatch = useDispatch()
  const theme: Theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()

  const [showProductCopyDialog, setShowProductCopyDialog] = useState<boolean>(false)
  const [showPasswordDialog, setShowPasswordDialog] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string | undefined>()
  const [user, setUser] = useState()
  const [hintColor, setHintColor] = useState(theme.typography.body1.color)

  useEffect(() => {
    const url = `${basePath}/api/session/user`
    props.entityId && fetch(url,
      {
        method: 'GET',
        credentials: 'include',
      })
      .then(response => response.json())
      .then(json => {
        setUser(json)
      })
  },
  [],
  )

  const setPassword = () => {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    const url = `${basePath}/api/user/${props.entityId}/password`
    const json = {
      userId: props.entityId,
      newPassword: newPassword,
    }
    props.entityId && fetch(url,
      {
        method: 'PUT',
        credentials: 'include',
        headers: requestHeaders,
        body: JSON.stringify(json),
      })
      .then(res => {
        setNewPassword(undefined)
        let message: Message = {
          severity: Severity.success,
          message: t('common:passwordChangeError'),
        }
        if (res.status < 400) {
          message = {
            severity: Severity.success,
            message: t('common:successfullyChanged'),
          }
        }
        // Verletzung Passwortrestriktionen
        if (res.status === 406) {
          setHintColor(theme.palette.error.main)
        } else {
          setShowPasswordDialog(false)
          setHintColor(theme.typography.body1.color)
        }

        dispatch(settingsActions.setMessage(message))
      })
  }

  const copyProduct = () => {
    const url = `${basePath}/api/parent/product/${props.entityId}/duplicate`
    props.entityId && fetch(url,
      {
        method: 'POST',
        credentials: 'include',
      })
      .then(response => response.json())
      .then(json => {
        history.push(`${json}`)
      })
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <>
      {props.entity === 'Product' && user && user.hasScopeWriteProducts &&
        <>
          <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={() => setShowProductCopyDialog(true)}>{t('common:copyProduct')}</Button>
          <DialogComponent
            request={false}
            open={showProductCopyDialog}
            dialogWidth='400px'
            content={
              <>
                {/* TODO Hinweise in language.json auslagern */}
                <Typography variant='body1' sx={{ marginBottom: '10px' }}>Möchtest du das Produkt wirklich kopieren? Dieser Vorgang kann nicht rückgängig gemacht werden.</Typography>
                <Box>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setShowProductCopyDialog(false)
                    }}
                  >{t('common:no')}
                  </Button>
                  <Button
                    color='primary'
                    onClick={() => {
                      setShowProductCopyDialog(false)
                      copyProduct()
                    }}
                  >{t('common:yes')}
                  </Button>
                </Box>
              </>
            }
            title={t('common:copyProduct')}
            onClose={() => setShowProductCopyDialog(false)}
            autoClose={false}
          />
        </>}

      {props.entity === 'BackendUser' &&
        <Button
          disabled={props.entityId === 'new'}
          style={{ marginLeft: '5px', width: '100%' }}
          variant='outlined' color='primary'
          onClick={() => setShowPasswordDialog(true)}
        >{t('common:setPassword')}
        </Button>}
      <DialogComponent
        open={showPasswordDialog}
        dialogWidth='400px'
        content={
          <>
            {/* TODO Hinweise in language.json auslagern */}
            <Typography variant='body1' sx={{ marginBottom: '10px' }}>Bitte geben Sie hier ein neues Passwort ein.</Typography>
            <Typography variant='body1' color={hintColor}><b>Passwort-Richtlinie:</b>
              <ul>
                <li>Länge das Kennwortes: 10 Zeichen</li>
                <li>Das Kennwort muss Zeichen aus drei der folgenden Kategorien enthalten:
                </li>
                <ul>
                  <li>
                    Großbuchstaben (A bis Z)
                  </li>
                  <li>
                    Kleinbuchstaben (a bis z)
                  </li>
                  <li>
                    Zahlen zur Basis 10 (0 bis 9)
                  </li>
                  <li>
                    Nicht alphabetische Zeichen (. , _ - +)
                  </li>
                </ul>
                <li>
                  Umlaute (ÄÖÜ) und ß sind keine zulässigen Zeichen
                </li>
              </ul>
            </Typography>
            <FormControl sx={{ m: 1, width: '100%' }} variant='outlined'>
              <InputLabel htmlFor='outlined-adornment-password'>Passwort</InputLabel>
              <OutlinedInput
                fullWidth
                id='outlined-adornment-password'
                type={showPassword ? 'text' : 'password'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewPassword(event.target.value)
                }}
                required
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Passwort'
              />
            </FormControl>
            <Box>
              <Button
                color='secondary'
                disabled={!newPassword}
                onClick={() => {
                  setShowPasswordDialog(false)
                }}
              >{t('common:cancel')}
              </Button>
              <Button
                color='primary'
                disabled={!newPassword}
                onClick={() => {
                  if (newPassword) {
                    setPassword()
                  }
                }}
              >{t('common:submit')}
              </Button>
            </Box>
          </>
        }
        title={t('common:changePasswordTitle')}
        request={false}
        onClose={() => setShowPasswordDialog(false)}
        autoClose={false}
      />
    </>
  )
}
export default TableActionButtons
