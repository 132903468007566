import { createSelector } from 'reselect'
import { SettingsRootState } from '../root.reducer'

export const getPrefersDarkMode = createSelector(
  (state: SettingsRootState) => state.settingsStore.prefersDarkMode,
  (state) => state,
)

export const getAppLayout = createSelector(
  (state: SettingsRootState) => state.settingsStore.appLayout,
  (state) => state,
)

export const getEditLayout = createSelector(
  (state: SettingsRootState) => state.settingsStore.editLayout,
  (state) => state,
)

export const getTheme = createSelector(
  (state: SettingsRootState) => state.settingsStore.theme,
  (state) => state,
)

export const getCrudeAppName = createSelector(
  (state: SettingsRootState) => state.settingsStore.crudeAppName,
  (state) => state,
)

export const getMessage = createSelector(
  (state: SettingsRootState) => state.settingsStore.message,
  (state) => state,
)

export const getUser = createSelector(
  (state: SettingsRootState) => state.settingsStore.user,
  (state) => state,
)
