import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Box, Tabs, Tab, Button } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import SwitchComponent from '../../shared/components/FormComponents/SwitchComponent'
import * as settingsActions from '../../store/settings/settings.actions'
import { getPrefersDarkMode } from '../../store/settings/settings.selectors'
import { Message, Severity } from '@3m5/crude-frontend/dist/shared/types/messageTypes'
import Env from '../../shared/config/envConfig'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const Settings: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const darkMode = useSelector(getPrefersDarkMode)

  function TabPanel (props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps (index: number) {
    return {
      id: `tab-${index}`,
    }
  }
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const exportFile = () => {
    const basePath = Env.basePath
    const url = `${basePath}/api/export/products/`
    fetch(url,
      {
        method: 'GET',
        credentials: 'include',
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = 'exportProducts.xlsx'
        document.body.appendChild(a)
        a.click()
        a.remove()
        const message: Message = {
          severity: Severity.error,
          message: t('messages:exportProductExcelFileError'),
        }
        dispatch(settingsActions.setMessage(message))
      })
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label={t('common:settings')} {...a11yProps(0)} />
            <Tab label={t('common:download')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <SwitchComponent
            label='Dark Mode'
            checked={darkMode}
            onChange={(checked: boolean) => {
              dispatch(settingsActions.setPrefersDarkMode(checked))
            }}
            name='checkedA'
            color='primary'
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Button style={{ marginLeft: '5px' }} variant='outlined' color='primary' onClick={exportFile}>{t('common:export')}</Button>
        </TabPanel>
      </Box>
    </>
  )
}
export default Settings
