import React, {
  useState,
} from 'react'

import SettingsButton from './SettingsButton'
import SettingsMenu from './SettingsMenu'
import { useSelector } from 'react-redux'
import { getUser } from '../../../store/settings/settings.selectors'

interface Props {
  doLogout: () => void
}

const HeaderComponent: React.FC<Props> = (props: Props) => {
  const user = useSelector(getUser)

  const [showSettingsMenu, setShowSettingsMenu] = useState(false)

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <SettingsButton
        user={user}
        showSettingsMenu={showSettingsMenu}
        setShowSettingsMenu={setShowSettingsMenu}
      />
      <SettingsMenu
        user={user}
        showSettingsMenu={showSettingsMenu}
        setShowSettingsMenu={setShowSettingsMenu}
        doLogout={props.doLogout}
      />
    </div>
  )
}

export default HeaderComponent
