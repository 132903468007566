import { EditLayout, StringLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const editConfig = (): EditLayout => {
  const config: EditLayout = {
    version: 1,
    entities: [
      {
        entity: 'Tracer',
        elements: [
          {
            name: 'id',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'tracerShortNameEnglish',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'tracerShortNameGerman',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'imageId',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'TracerCategory',
        elements: [
          {
            name: 'id',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'displayNameEnglish',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'displayNameGerman',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'BackendUser',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'crudeActionElement',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'login',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'canCreateUsers',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'canCreateProducts',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'canUseUploadModule',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'canOnlyReadProducts',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'canDeleteAndPublishProducts',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'permissions',
                title: {
                  'de-DE': 'Einstellungen',
                  'en-US': 'Permissions',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'DocumentCodePdf',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'code',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'displayName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'pdfFileId',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'general',
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'ProductPdf',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'pdfFileId',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'displayName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'product',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'general',
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'Product',
        elements: [
          {
            name: 'id',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'productNumber',
            layout: StringLayout.singleline,
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'productNameGerman',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'productNameEnglish',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'subLineGerman',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'subLineEnglish',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'chemicalName',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 2,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'synonyms',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 2,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'imageId',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'publishInCatalog',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'quality1',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'quality2',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'productClass',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 2,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'packageSize',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'measurementUnit',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'compatibility',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'storageType',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'usedPrecursor',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'molFormula',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'molarMass',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'casNumber',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'purity',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 9,
                to: 9,
              },
            },
          },
          {
            name: 'characteristic1',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'characteristic2',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'characteristic3',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 10,
                to: 10,
              },
            },
          },
          {
            name: 'characteristic4',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
          },
          {
            name: 'characteristic5',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 11,
                to: 11,
              },
            },
          },
          {
            name: 'certificates',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 12,
                to: 12,
              },
            },
          },
          {
            name: 'literature1',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 12,
                to: 12,
              },
            },
          },
          {
            name: 'literature2',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 12,
                to: 12,
              },
            },
          },
          {
            name: 'literature3',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 13,
                to: 13,
              },
            },
          },
          {
            name: 'literature4',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 13,
                to: 13,
              },
            },
          },
          {
            name: 'literature5',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 14,
                to: 14,
              },
            },
          },
          {
            name: 'internContact',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 15,
                to: 15,
              },
            },
          },
          {
            name: 'firstRetestAfter',
            editTab: 'details',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 15,
                to: 15,
              },
            },
          },
          {
            name: 'supplement',
            layout: StringLayout.multiline,
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 15,
                to: 15,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 1,
                  },
                },
                title: {
                  'de-DE': 'Allgemeine Informationen',
                  'en-US': 'General Data',
                },
                name: 'general',
                initialOpen: true,
                closable: false,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 2,
                    to: 3,
                  },
                },
                title: {
                  'de-DE': 'Weitere Bezeichnungen',
                  'en-US': 'Additional Data',
                },
                name: 'additional',
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 2,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 1,
                  },
                },
                title: {
                  'de-DE': 'Darstellung',
                  'en-US': 'Images',
                },
                name: 'images',
                initialOpen: true,
                closable: false,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 4,
                    to: 7,
                  },
                },
                title: {
                  'de-DE': 'Qualität',
                  'en-US': 'Quality',
                },
                name: 'quality',
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 2,
                  },
                  vertical: {
                    from: 4,
                    to: 7,
                  },
                },
                title: {
                  'de-DE': 'Produkt',
                  'en-US': 'Product',
                },
                name: 'product',
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 8,
                    to: 11,
                  },
                },
                title: {
                  'de-DE': 'Charakteristik',
                  'en-US': 'Characteristics',
                },
                name: 'characteristics',
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 12,
                    to: 14,
                  },
                },
                title: {
                  'de-DE': 'Zertifikate',
                  'en-US': 'Certificates',
                },
                name: 'certificates',
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 2,
                  },
                  vertical: {
                    from: 12,
                    to: 14,
                  },
                },
                title: {
                  'de-DE': 'Literatur',
                  'en-US': 'Literature',
                },
                name: 'literature',
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 15,
                    to: 15,
                  },
                },
                title: {
                  'de-DE': 'Interne Felder',
                  'en-US': 'Internal',
                },
                name: 'internal',
                initialOpen: false,
                closable: true,
              },
            ],
          },
        ],
      },
    ],
  }
  return config
}
export default editConfig
