import { CrudeLicenses, License } from '@3m5/crude-frontend/dist/shared/types/licenseTypes'

const crudeLicense = (): CrudeLicenses => {
  return {
    [License.standardFilter]: {
      available: true,
    },
    [License.outgoingReferenceFilters]: {
      available: true,
    },
    [License.extendedEditForms]: {
      available: true,
    },
    [License.i18n]: {
      available: true,
    },
    [License.extendedNavigation]: {
      available: true,
    },
    [License.richTextEditor]: {
      available: true,
    },
    [License.extendedTable]: {
      available: true,
    },
    [License.configurableTable]: {
      available: true,
    },
    [License.fileUpload]: {
      available: true,
    },
    [License.treeView]: {
      available: true,
    },
    [License.tooltips]: {
      available: true,
    },
    [License.freeJSON]: {
      available: true,
    },
    [License.integratedHTML]: {
      available: true,
    },
    [License.imageCropper]: {
      available: true,
    },
    [License.responsiveViews]: {
      available: false,
    },
  }
}
export default crudeLicense
