import { CrudeTab } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
import { Order, TableTextAlignment } from '@3m5/crude-frontend/dist/shared/types/commonTypes'

const crudeTabConfig = (): CrudeTab[] => {
  return [
    {
      entity: 'Product',
      columns: [
        {
          name: 'productNumber',
          hideInTableView: false,
          pinned: true,
          columnWidth: 80,
          align: TableTextAlignment.right,
        },
        {
          name: 'productNameEnglish',
          hideInTableView: false,
          pinned: true,
          columnWidth: 250,
          sort: Order.asc,
        },
        {
          name: 'subLineEnglish',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'publishInCatalog',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'productClass',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'compatibility',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'quality1',
          hideInTableView: false,
          pinned: false,
          columnWidth: 120,
        },
        {
          name: 'quality2',
          hideInTableView: false,
          pinned: false,
          columnWidth: 120,
        },
        {
          name: 'characteristic1',
          hideInTableView: false,
          pinned: false,
          columnWidth: 120,
        }, {
          name: 'characteristic2',
          hideInTableView: false,
          pinned: false,
          columnWidth: 120,
        }, {
          name: 'characteristic3',
          hideInTableView: false,
          pinned: false,
          columnWidth: 120,
        },
        {
          name: 'characteristic4',
          hideInTableView: false,
          pinned: false,
          columnWidth: 120,
        },
        {
          name: 'characteristic5',
          hideInTableView: false,
          pinned: false,
          columnWidth: 120,
        },
        {
          name: 'storageType',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'packageSize',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'measurementUnit',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'internContact',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'certificates',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'firstRetestAfter',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'chemicalName',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'synonyms',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'casNumber',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'molFormula',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'molarMass',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'purity',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'imageId',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'literature1',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'literature2',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'literature3',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'literature4',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'literature5',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'supplement',
          hideInTableView: false,
          pinned: false,
          columnWidth: 100,
        },
        {
          name: 'productNameGerman',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'subLineGerman',
          hideInTableView: false,
          pinned: false,
          columnWidth: 250,
        },
        {
          name: 'extendedShorthand',
          hideInTableView: true,
          pinned: false,
        },
        {
          name: 'usedPrecursor',
          hideInTableView: true,
          pinned: false,
        },
        {
          name: 'structureFormula',
          hideInTableView: true,
          pinned: false,
        },
        {
          name: 'crudeAction',
          hideInTableView: false,
          columnWidth: 160,
          pinned: true,
          align: TableTextAlignment.right,
        },
      ],
      tabs: [
        {
          sourceEntity: 'RelatedProducts',
          sourceColumn: 'targetProduct',
          hideEntity: false,
          label: {
            'de-DE': 'Verwandte Produkte',
          },
        },
        {
          sourceEntity: 'ProductHasRelatedTracer',
          sourceColumn: 'product',
          hideEntity: false,
          label: {
            'de-DE': 'Verwandte Tracer',
          },
        },
        {
          sourceEntity: 'ProductHasTracer',
          sourceColumn: 'product',
          hideEntity: false,
          label: {
            'de-DE': 'Tracer',
          },
        },
        {
          sourceEntity: 'ProductHasTracerCategory',
          sourceColumn: 'product',
          hideEntity: false,
          label: {
            'de-DE': 'Tracer Kategorie',
          },
        },
        {
          sourceEntity: 'RelatedProducts',
          sourceColumn: 'relatedProduct',
          hideEntity: true,
        },
      ],
    },
    {
      entity: 'ProductCategory',
      columns: [
        {
          name: 'displayName',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'ProductClass',
      columns: [
        {
          name: 'displayName',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'Tracer',
      columns: [
        {
          name: 'tracerShortNameEnglish',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
      tabs: [
        {
          sourceEntity: 'ProductHasTracer',
          sourceColumn: 'tracer',
          hideEntity: false,
          label: {
            'de-DE': 'Produkte',
          },
        },
        {
          sourceEntity: 'ProductHasRelatedTracer',
          sourceColumn: 'relatedTracer',
          hideEntity: false,
          label: {
            'de-DE': 'Dazugehörige Produkte',
          },
        },
      ],
    },
    {
      entity: 'TracerCategory',
      columns: [
        {
          name: 'displayNameEnglish',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'Quality1',
      columns: [
        {
          name: 'displayName',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'Quality2',
      columns: [
        {
          name: 'displayNameEnglish',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'StorageType',
      columns: [
        {
          name: 'displayName',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'Application',
      columns: [
        {
          name: 'displayName',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'Compatibility',
      columns: [
        {
          name: 'sortPriorityNumber',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'DocumentCodePdf',
      columns: [
        {
          name: 'displayName',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'ProductPdf',
      columns: [
        {
          name: 'displayName',
          hideInTableView: false,
          sort: Order.asc,
        },
      ],
    },
    {
      entity: 'BackendUser',
      columns: [
        {
          name: 'login',
          hideInTableView: false,
          sort: Order.asc,
        },
        {
          name: 'crudeAction',
          hideInTableView: false,
          columnWidth: 120,
          pinned: true,
          align: TableTextAlignment.right,
        },
      ],
    },
  ]
}
export default crudeTabConfig
